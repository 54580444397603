import { AttributeValue } from "./AttributeValue";
import { Instance } from "./Instance";
import { Instruction } from "./Instruction";
import { Note } from "./Note";
import { View } from "./View";

export enum DocumentStatus {
  New = "NEW",
  EditedEditorial = "EDITED_EDITORIAL",
  EditedStudio = "EDITED_STUDIO",
  PostponedForPublishing = "POSTPONED_FOR_PUBLISHING",
  ReadyForPublishing = "READY_FOR_PUBLISHING",
  NotForPublishing = "NOT_FOR_PUBLISHING",
  Wanted = "WANTED",
  Acquisition = "ACQUISITION",
  Preview = "PREVIEW",
}

export interface DocumentSeries {
  id?: string;
  documentTemplateId?: string;
  documentTypeId?: string;
  materialTypeId?: string;
  code?: string;
  status: DocumentStatus;
  width?: number;
  height?: number;
  exportable?: boolean;
  instances?: Instance[];
  attributeValues?: AttributeValue[];
  instructions?: Instruction[];
  notes?: Note[];
  views: View[];
}
