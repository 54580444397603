export const CONST_ATTRIBUTES = {
  singular: "attribute",
  plural: "attributes",
};
export const CONST_ATTRIBUTES_GROUPS = {
  plural: "attributeGroups",
};
export const CONST_COUNTRIES = {
  singular: "country",
  plural: "countries",
};
export const CONST_CURRENCIES = {
  singular: "currency",
  plural: "currencies",
};
export const CONST_DOCUMENT_CODES = "keesing/CodeGenerator";
export const CONST_DOCUMENT_SERIES = {
  plural: "documentSeries",
};
export const CONST_DOCUMENT_TEMPLATES = {
  singular: "documentTemplate",
  plural: "documentTemplates",
};
export const CONST_DOCUMENT_TEMPLATES_GROUPS = {
  singular: "documentTemplateGroup",
  plural: "documentTemplateGroups",
};
export const CONST_DOCUMENT_TYPES = {
  singular: "documentType",
  plural: "documentTypes",
};
export const CONST_NOTIFICATIONS = {
  singular: "notification",
  plural: "notifications",
};
export const CONST_REGIONS = { singular: "region", plural: "regions" };
export const CONST_SECURITY_FEATURES = {
  singular: "securityFeature",
  plural: "securityFeatures",
};
export const CONST_SECURITY_FEATURES_GROUPS = {
  plural: "securityFeatureGroups",
};
export const CONST_LANGUAGES = {
  singular: "language",
  plural: "languages",
};
export const CONST_MATERIAL_TYPES = {
  singular: "materialType",
  plural: "materialTypes",
};
export const CONST_ORGANIZATIONS = {
  singular: "organization",
  plural: "organizations",
};
export const CONST_FIELDS = {
  singular: "field",
  plural: "fields",
};
export const CONST_FIELDS_GROUPS = {
  singular: "fieldGroup",
  plural: "fieldGroups",
};
